<template>
  <div class="mt-5" >     
      <v-row align="center" class="primary pa-5 white--text">         
        <v-col cols="12" v-if="admitted">
            <h2>Offer of Admission</h2>
            <p>Beloved,</p>
            <p>Calvary greetings to you in the name of our Lord and Savior Jesus Christ.</p>
		
            <p>With reference to your application to the Peace House Agricultural Training Institute (ATI) and having gone through our interview, we are pleased to inform you that you have been offered admission leading to Holistic Life Training, Comprehensive Agricultural Skill Acquisition and an award of diploma in Agricultural Skills with effect from 2023 Session.</p>
            
            <p>Click here to <v-btn href="/docs/admission-letter.pdf" download small><v-icon>mdi-download</v-icon> Download Admission Letter</v-btn>.</p>
            <p>Click here to <v-btn href="/docs/list-of-items.pdf" download small><v-icon>mdi-download</v-icon> Download List of Required Items</v-btn>.</p>
            <p>Click here to <v-btn :to="{name: 'Acceptance', params:{editUser}}" small> Fill Acceptance Form</v-btn>.</p>

            <h5>Dr. (Mrs) P. A. Omololu.</h5>
            <h5>Director, PHATI.</h5>
        </v-col>
        
        <v-col cols="12" v-else-if="shortlisted">
            <h2>Invitation for Interview</h2>
            <p>Beloved,</p>
            <p>Calvary greetings to you in the name of our Lord and Savior Jesus Christ.</p>
		
            <p>Sequel to your application to the Peace House Agricultural Training Institute (ATI), we would like to inform you that you have been shortlisted for interview.</p>
            
            <p>Click here to <v-btn href="/docs/ati-2022-interview-letter.pdf" download small><v-icon>mdi-download</v-icon> Download Invitation Letter</v-btn> for the interview.</p>

            <h5>Dr. (Mrs) P. A. Omololu.</h5>
            <h5>Director, PHATI.</h5>
        </v-col>
        <v-col cols="12">
          <hr class="my-2">
            <h2>Welcome {{ fullName }}</h2>
            <p>You can click the button to {{ "   " }}  
          <v-btn @click="continueApplication" color="success" small> Edit Your Application Form</v-btn></p>
        </v-col>             
      </v-row>

    <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
      {{ snackMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from "@/mixins";

export default {
  name: "ApplicantDashboard",
  mixins: [snackMixin],
  props: {
    editUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {     
    };
  },
  computed: {
    shortlisted(){
      return this.editUser && this.editUser.shortlisted == 1;
    },
    admitted(){
      return this.editUser && this.editUser.admitted == 1;
    },
    fullName(){
      return this.editUser && this.editUser.surname + " " + this.editUser.other_names;
    }
  },
  methods: {
    continueApplication() {
      this.$router.push({
        name:"Application",
        params:{editUser: this.editUser}
      });
    },
  },
};
</script>
